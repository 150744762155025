import React from 'react';

import { ToastContext } from './ToastContext';
import { Toast } from '../../components-shared/Toast';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { IToastProviderProps, ToastType } from './interfaces';

export const ToastProvider: React.FC<IToastProviderProps> = (props) => {
  const [opened, setOpened] = React.useState<boolean>(false);
  const [content, setContent] = React.useState<any>(null);
  const [theme, setTheme] = React.useState<ToastType>(ToastType.success);
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const { errorMessageKey } = props;

  const showToast = React.useCallback(
    (_content, opts) => {
      setOpened(true);
      setContent(_content);
      setTheme(opts?.type);
    },
    [setOpened, setContent, setTheme],
  );
  React.useEffect(() => {
    if (errorMessageKey) {
      showToast(t(errorMessageKey), { type: ToastType.error });
    }
  }, [errorMessageKey]);
  return (
    <ToastContext.Provider
      value={{
        showToast,
      }}
    >
      <Toast
        isMobile={isMobile}
        type={theme}
        onClose={() => {
          if (props.errorMessageKey) {
            props.clearError();
          }
          setOpened(false);
        }}
        opened={opened}
      >
        {content}
      </Toast>
      {props.children}
    </ToastContext.Provider>
  );
};
