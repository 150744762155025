import React from 'react';

import {
  Feedback,
  ParticipantStep,
  QuizSubmission,
} from '@wix/ambassador-challenges-v1-participant/types';

export interface IResolveStepContext {
  isResolveStepRequestInProgress: boolean;
  resolveStep(
    stepId: string,
    feedback?: Feedback,
    quizSubmission?: QuizSubmission,
    isUpdate?: boolean,
  ): Promise<void>;
  resolveStepData: Pick<
    ParticipantStep,
    'id' | 'quizSubmission' | 'transitions'
  >;
  resolveStepError: string;
}

export const ResolveStepContext = React.createContext<IResolveStepContext>({
  isResolveStepRequestInProgress: false,
  resolveStep: (() => {}) as any,
  resolveStepData: null,
  resolveStepError: null,
});

export const useResolveStep = () => React.useContext(ResolveStepContext);
